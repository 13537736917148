
@import './fonts';
// @import './tools/vars';
// @import './tools/customize-bootstrap';
// @import './tools/bootstrap-source/bootstrap';


// This styles are essential for theme
// @import './basics/app';
// @import './basics/helpers';

// 3 - Component
//---------------------------------------------------

// @import './components/header';
// @import './component/sidebar';
// @import './component/sidebar-mini';

// // This styles are optional based on your preferance
// @import './component/dropdown';
// @import './component/button';
// @import './components/page-title';
// @import './component/tile';
// @import './component/custom-radio-n-checkbox';
// @import './component/toggle-button';
// @import './component/material-half-bg';
// @import './component/material-loader';
// @import './component/button-spinner';
// @import './component/widgets';
// @import './component/chat';

// // Include the following style if you need RTL support
// // Also you will need to add dir="rtl" attribute to HTML tag

// //@import './component/rtl';

// // 4 -Pages
// //---------------------------------------------------

// // This styles are optional based on your preferance
// @import './pages/landing-page';
// @import './pages/docs';
// @import './pages/login-material';
// @import './pages/lockscreen-material';
// @import './pages/error-page';
// @import './pages/mailbox';
// @import './pages/user-profile';

/* #root{
    display: none;
} */
.w1 {    width: 1rem; }
.w2 {    width: 2rem; }
.w3 {    width: 4rem; }
.w4 {    width: 8rem; }
.w5 {    width: 16rem; }
.d-flex {
    display: flex;
}
.flex-0 {
    flex: 0;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-col-3 {
    flex: 1 0 20%;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.p-0 {
    padding: 0rem;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 1rem;
}
.p-4 {
    padding: 1.5rem;
}
.p-5 {
    padding: 3rem;
}
/* padding left */
.pl-0 {
    padding-left: 0rem;
}
.pl-1 {
    padding-left: 0.25rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-3 {
    padding-left: 1rem;
}
.pl-4 {
    padding-left: 1.5rem;
}
.pl-5 {
    padding-left: 3rem;
}
/* padding right */
.pr-0 {
    padding-right: 0rem;
}
.pr-1 {
    padding-right: 0.25rem;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-3 {
    padding-right: 1rem;
}
.pr-4 {
    padding-right: 1.5rem;
}
.pr-5 {
    padding-right: 3rem;
}
/* padding top */
.pt-0 {
    padding-top: 0rem;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 1rem;
}
.pt-4 {
    padding-top: 1.5rem;
}
.pt-5 {
    padding-top: 3rem;
}
/* padding bottom */
.pb-0 {
    padding-bottom: 0rem;
}
.pb-1 {
    padding-bottom: 0.25rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-3 {
    padding-bottom: 1rem;
}
.pb-4 {
    padding-bottom: 1.5rem;
}
.pb-5 {
    padding-bottom: 3rem;
}
/* margin top */
.mt-0 {
    margin-top: 0rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-3 {
    margin-top: 1rem;
}
.mt-4 {
    margin-top: 1.5rem;
}
.mt-5 {
    margin-top: 3rem;
}
/* margin left */
.ml-0 {
    margin-left: 0rem;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-3 {
    margin-left: 1rem;
}
.ml-4 {
    margin-left: 1.5rem;
}
.ml-5 {
    margin-left: 3rem;
}
/* margin bottom */
.mb-0 {
    margin-bottom: 0rem;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.mb-5 {
    margin-bottom: 3rem;
}

.ws-nowrap {
    white-space: nowrap;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-column { 
    flex-direction: column; 
}

.flex-row { 
    flex-direction: row; 
}

.p-input {
    padding: 8px 12px;
}

.bg-white {
    background-color: white !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.bg-warning {
    color: #212529;
    background-color: #ffc107 !important;
}

.line-head {
    font-weight: 400;
    margin-top: 5px;
}

.fw-600 {
    font-weight: 600;
}
.fw-400 {
    font-weight: 400;
}
.fw-200 {
    font-weight: 200;
}

.has-float-label {
    display: block;
    position: relative;

    label, > span {
        position: absolute;
        left: 0;
        top: 0;
        cursor: text;
        font-size: 75%;
        opacity: 1;
        -webkit-transition: all .2s;
                transition: all .2s;
        top: -.5em;
        left: 1.25rem;
        z-index: 2;
        line-height: 1;
        padding: 0 1px;
        background: white;
        font-weight: 400;

        &::after, &::after {
            content: " ";
            display: block;
            position: absolute;
            background: white;
            height: 2px;
            top: 50%;
            left: -.2em;
            right: -.2em;
            z-index: -1;
        }
    }

    .form-control::-webkit-input-placeholder {
        opacity: 1;
        -webkit-transition: all .2s;
                transition: all .2s;
    }

    .form-control::-moz-placeholder {
        opacity: 1;
        transition: all .2s;
    }

    .form-control:-ms-input-placeholder {
        opacity: 1;
        transition: all .2s;
    }

    .form-control::placeholder {
        opacity: 1;
        -webkit-transition: all .2s;
                transition: all .2s;
    }

    .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
        opacity: 0;
    }

    .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
        opacity: 0;
    }

    .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
        opacity: 0;
    }

    .form-control:placeholder-shown:not(:focus)::placeholder {
        opacity: 0;
    }

    .form-control:placeholder-shown:not(:focus) + * {
        font-size: 100%;
        opacity: .8;
        top: .85em;
    }
}

.input-group {
    .has-float-label {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        margin-bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        
        .form-control {
            width: 100%;
            border-radius: 0.25rem;
        }
    }

    .has-float-label:not(:last-child), .has-float-label:not(:last-child) .form-control {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: 0;
    }

    .has-float-label:not(:first-child), .has-float-label:not(:first-child) .form-control {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

#printPdf { 
    position: fixed; top: 0px; left: 0px; display: block;       
    padding: 0px;border: 0px;margin: 0px;
    visibility: hidden; opacity: 0; 
}

.css-table {
    display: table;
    width: 100%;

    .css-table-header {
        display: table-header-group;
        font-weight: bold;
        // background-color: rgb(191, 191, 191);    
    }

     
    .css-table-body {
        display: table-row-group;
    }
        
    .css-table-row {
        display: table-row;
    }
        
    .css-table-header, .css-table-row {
        div.css-table-col {
            display: table-cell;
            padding: 6px 8px;
            border-bottom: 1px solid rgb(191, 191, 191);
        }
    }
        
    .css-table-header {
        div {
            // text-align: center;
            border-bottom: 2px solid rgb(191, 191, 191);
        }
    }
}
    
.rs-tag-text {
    white-space: nowrap;
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}
  
.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}
  
.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
  
.react-confirm-alert-body {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    width: 478px;
    padding: 20px;
    text-align: left;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-icon:first-child {
    margin-top: 32px;
}
    
.react-confirm-alert-icon {
    width: 80px;
    height: 80px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    padding: 0;
    position: relative;
    box-sizing: content-box;
    margin: 20px auto;
    
}

.react-confirm-alert-icon--info {
    border-color: #c9dae1;
}

.react-confirm-alert-icon--info:after, .react-confirm-alert-icon--info:before {
    content: "";
    position: absolute;
    left: 50%;
    background-color: #c9dae1;
}

.react-confirm-alert-icon--info:before {
    width: 5px;
    height: 29px;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
}

.react-confirm-alert-icon--info:after {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
}

.react-confirm-alert-icon--info:after, .react-confirm-alert-icon--info:before {
    content: "";
    position: absolute;
    left: 50%;
    background-color: #c9dae1;
}


.react-confirm-alert-icon--info:after, .react-confirm-alert-icon--info:before {
    content: "";
    position: absolute;
    left: 50%;
    background-color: #c9dae1;
}


.react-confirm-alert-icon--warning {
    border-color: #f8bb86;
}

.react-confirm-alert-icon--warning:before {
    width: 5px;
    height: 29px;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
}

.react-confirm-alert-icon--warning:after {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
}

.react-confirm-alert-icon--warning:after, .react-confirm-alert-icon--warning:before {
    content: "";
    position: absolute;
    left: 50%;
    background-color: #f8bb86;
}

.react-confirm-alert-title {
    color: rgba(0,0,0,.65);
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}
  
.react-confirm-alert-text {
    font-size: 16px;
    position: relative;
    float: none;
    line-height: normal;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0 10px;
    margin-bottom: 20px;
    font-weight: 400;
    color: rgba(0,0,0,.64);
    max-width: calc(100% - 20px);
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}
  
.react-confirm-alert-body > h1 {
    margin-top: 0;
}
  
.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}
  
.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}
  
.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}
  
@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.badge-danger {
    background-color: rgb(220, 53, 69);
}

.badge-success {
    background-color: rgb(23, 162, 184);
}

.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

p.title-line {
    padding-bottom: 5px;
    border-bottom: 2px solid lightgrey;
    font-weight: 600;
}

.overlay-container {
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        color: #555;
        border-radius: 3px;
    }
}

div.dv-hover {
    animation-name: react-confirm-alert-fadeIn;
    animation-duration: 1s;
    // &:hover {
//         background-color: #efefef;
    // }
}


.border-corner {
    width: 100px;
    height: 100px;

    background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
        linear-gradient(to right, black 4px, transparent 4px) 0 100%,
        linear-gradient(to left, black 4px, transparent 4px) 100% 0,
        linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
        linear-gradient(to top, black 4px, transparent 4px) 0 100%,
        linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
}

input.input-element, textarea.input-element {
    width: 100%;
    background: transparent;
    border: 1px solid #efefef;
    text-align: center;
}

input.input-element:focus, textarea.input-element:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
