@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Niconne);

.f1 { font-size: 3rem; }
.f2 { font-size: 2.25rem; }
.f3 { font-size: 1.5rem; }
.f4 { font-size: 1.25rem; }
.f5 { font-size: 1rem; }
.f6 { font-size: .875rem; }
.f7 { font-size: .75rem; }

.fwb    { font-weight: bold; }
.fw1    { font-weight: 100; }
.fw2    { font-weight: 200; }
.fw3    { font-weight: 300; }
.fw4    { font-weight: 400; }
.fw5    { font-weight: 500; }
.fw6    { font-weight: 600; }
.fw7    { font-weight: 700; }
.fw8    { font-weight: 800; }
.fw9    { font-weight: 900; }