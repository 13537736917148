
.top-nav .alert-button {
    position: relative;
}

.top-nav .alert-button .alert-button__badge {
    background-color: #fa3e3e;
    border-radius: 8px;
    color: white;
    padding: 5px 4px;
    font-size: 8px;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 2px;
}